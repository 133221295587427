import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Grid, Box, Heading, Text, Image, Button, Divider } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from '../components/seo';
import WebDesignHero from "../components/WebDesignHero";
import GatsbyLogo from "../images/Gatsby-Logo.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="WordPress & Gatsby Web Design and Development Agency in Surrey" description="" mdxType="SEO" />
    <WebDesignHero mdxType="WebDesignHero" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
      <p>{`Primarily we design and develop customised websites using WordPress, which powers over 37% of all websites. When performance really matters and budgets allow, we build bespoke sites with GatsbyJS, for blazing fast, SEO-friendly websites like this one.`}</p>
      <p>{`We are specialists in developing and hosting customised websites built on WordPress.`}</p>
      <p>{`WordPress currently powers 1 out of every 3 websites running on the web.`}</p>
      <p>{`Passionate about building with WordPress`}</p>
      <p>{`We love working with WordPress which is why we have been using it as our platform of choice for over 10 years.`}</p>
      <p>{`WordPress has allowed us to build really powerful and performant websites for clients at a fraction of the cost of a bespoke, hand-crafted site.`}</p>
    </Box>
    {
      /* <Box sx={{ pt: [4, 5, 5], pb: [4, 5, 5], pl: [3, 5, "80px"], pr: [3, 5, "80px"] }}>
         <Grid
             gap={[ 1, 1, 1 ]}
             columns={[ 1, 2, 3 ]}>
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
             />
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
                 width="100%"
             />
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
                 width="100%"
             />
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
                 width="100%"
             />
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
                 width="100%"
             />
             <Image sx={{ 
                 }}
                 src={GatsbyLogo}
                 title="Gatsby logo"
                 width="100%"
             />
         </Grid>
      </Box> */
    }
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
    <Grid gap={[0, 4, 5]} columns={[1, 2, 2]} mdxType="Grid">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: [4, 0, 0],
          pb: [3, 0, 0]
        }} mdxType="Box">
            <Heading sx={{
            variant: "styles.h2",
            fontWeight: "700"
          }} mdxType="Heading">When performance is paramount, we build with GatsbyJS</Heading>
            <Image sx={{
            pt: [3, 4, 4],
            pb: [3, 3, 4],
            pl: [0, 0, 0],
            pr: ['50%', '20%', '40%']
          }} src={GatsbyLogo} title="Gatsby logo" width="100%" mdxType="Image" />
        </Box>
        <Box mdxType="Box">
            <Heading as='h3' sx={{
            variant: "styles.h3"
          }} mdxType="Heading">The Modern Way To Build The Web</Heading>
            <Text mdxType="Text">Gatsby is a modern website framework that builds performance into every website by
            leveraging the latest web technologies such as React, GraphQL, and modern Javascript.
            Forget complicated deploys with expensive, time-consuming setup and hosting costs,
            maintenance, and scaling fears. Bring your content from anywhere and Gatsby does the
            compiling for you to create a streamlined, highly performant website. Gatsby websites
            are lightning-quick, instantly scalable, inherently secure, and built with modern tools for
            the modern world. Create blazing fast, compelling apps and websites on the platform that
            makes you a performance expert without needing to become a performance expert.</Text>
            <Heading as='h3' sx={{
            variant: "styles.h3",
            mt: 3
          }} mdxType="Heading">All Gatsby sites are...</Heading>
            <ul style={{
            listStyle: "none",
            paddingLeft: 0
          }}>
            <li style={{
              paddingBottom: 10
            }}><span style={{
                "color": "#663499"
              }}><strong>SIMPLE</strong> -></span> No servers. No databases. Traditional hosting is costly, complex, and
            puts your site at risk of crashing if traffic spikes unexpectedly. Gatsby’s serverless
            architecture will never go down because it’s built on the edge, instantly scaling when
            your content goes viral.</li>
            <li style={{
              paddingBottom: 10
            }}><span style={{
                "color": "#663499"
              }}><strong>SECURE</strong> -></span> Gatsby websites are inherently more secure. All your content is compiled
            ahead of time so hackers can’t get to your database or into your CMS. And your site is
            more resilient to DDoS attacks because the biggest point of failure — a server — is no
            longer part of the equation.</li>
            <li style={{
              paddingBottom: 10
            }}><span style={{
                "color": "#663499"
              }}><strong>FLEXIBLE</strong> -></span> Build websites quickly and deploy them safely, no matter where your
            content lives. Load data from anywhere: your CMS, a database, static files&mdash;or even
            multiple places at once.</li>
            </ul>
        </Box>
    </Grid>
    </Box>
    <Box sx={{
      pt: 0,
      pb: 0,
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
    <Divider mdxType="Divider" />
    <h2 style={{
        textAlign: "center",
        marginTop: "40px"
      }}>Like what you see and ready to chat?</h2>
    <Button variant="cta" sx={{
        marginTop: [4, 5, 5],
        marginBottom: [3, 3, 3],
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        display: "block"
      }} mdxType="Button">
        <AniLink fade title="Get in touch with Thinkanew Media" duration={0.35} to={`/contact-us/`} style={{
          textDecoration: "inherit",
          color: "inherit",
          padding: "14px 20px"
        }} mdxType="AniLink">
        Get in touch
        </AniLink>
    </Button>  
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      